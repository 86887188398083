import React from "react";

import async from "./components/Async";

//Invoices
import InvoicesList from "./pages/invoices/ListInvoices";

import UploadInvoices from "./pages/Documents/UploadInvoices";

// import AddInvoices from "./pages/Invoices/AddInvoices";

// import NotClaimableInvoices from "./pages/invoices/NotClaimableInvoices";

// VAT
import ListClaims from "./pages/VAT/ListClaims";

//Excise
import ListExciseClaims from "./pages/excise/ListExciseClaims";

// // Layouts
import DashboardLayout from "./layouts/Dashboard";

// // Dashboard components
const Default = async(() => import("./pages/dashboard/Default"));

const routes = [
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "", // Empty path for the default page
        element: <Default />, // Replace with the component you want as the default page
      },
      {
        path: "dashboard/default",
        element: <Default />,
      },
      // ... other routes
    ],
  },
  {
    path: "invoices",
    element: <DashboardLayout />,
    children: [
      // {
      //   path: "addinvoices",
      //   element: <InvoicesAdd />,
      // },
      {
        path: "ListInvoices",
        element: <InvoicesList />,
      },
      // {
      //   path: "NotClaimableInvoices",
      //   element: <NotClaimableInvoices />,
      // },
    ],
  },
  {
    path: "VAT",
    element: <DashboardLayout />,
    children: [
      {
        path: "ListClaims",
        element: <ListClaims />,
      },
    ],
  },
  {
    path: "excise",
    element: <DashboardLayout />,
    children: [
      {
        path: "ListExciseClaims",
        element: <ListExciseClaims />,
      },
    ],
  },
  {
    path: "documents",
    element: <DashboardLayout />,
    children: [
      {
        path: "UploadInvoices",
        element: <UploadInvoices />,
      },
    ],
  },
];

export default routes;
