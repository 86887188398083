import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  // Alert as MuiAlert,
  Box,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Button as MuiButton,
  // TextField as MuiTextField,
  Divider,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import axios from "axios";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DataGrid, GridToolbar, GridFooter } from "@mui/x-data-grid";
// import moment from "moment";
import ViewCustomerInvoiceDialog from "../components/ViewCustomerInvoiceDialog";
import apiUtils from "../utils/apiUtils";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
// import List from "@mui/material/List";

const Card = styled(MuiCard)(spacing);
// const Alert = styled(MuiAlert)(spacing);
// const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

export default function ViewClaimDialog(props) {
  const supplierData = apiUtils.useApiData("/api/v1/suppliers");
  const currencyData = apiUtils.useApiData("/api/v1/currencies");
  const productData = apiUtils.useApiData("/api/v1/productTypes");
  // const invoiceLabelData = apiUtils.useApiData("/api/v1/invoiceLabels");
  // const invoiceStateData = apiUtils.useApiData("/api/v1/invoiceStates");
  const countryData = apiUtils.useApiData("/api/v1/countries");
  // const paymentTypeData = apiUtils.useApiData("/api/v1/paymentTypes");
  // const claimStateData = apiUtils.useApiData("/api/v1/claimStates");

  const { viewClaimDialog, setViewClaimDialog } = props;
  const customerId = viewClaimDialog?.custInfo[0]?.customerId;
  const customerNumber = viewClaimDialog?.custInfo[0]?.customerNumber;
  const [bankAccounts, setBankAccounts] = useState([]);

  useEffect(() => {
    if (!customerId) return;

    const fetchBankAccounts = async () => {
      try {
        const response = await axios.get("/api/v1/bankaccountsByEntityId/" + customerId);
        setBankAccounts(response.data);
      } catch (error) {
        console.error("Failed to fetch bank accounts", error);
      }
    };

    fetchBankAccounts();
  }, [customerId]);

  // console.log(viewClaimDialog);
  // alert(customerId);
  const [payableToDelta, setPayableToDelta] = useState(false); // You can set this to a predefined value if needed
  const [invoiceGridData, setInvoiceGridData] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [updatingClaim, setUpdatingClaim] = useState(false);
  const [pageSize, setPageSize] = React.useState(15);
  const [dataVersion] = useState(0);
  const selectedClaimId = useRef();

  // const [selectedPaymentTypeId, setSelectedPaymentTypeId] = useState("");
  const [selectedClaimStateId, setSelectedClaimStateId] = useState("");
  // const [selectedLiters, setSelectedLiters] = useState("");
  // const [selectedAdvancedRefundAmountEUR, setSelectedAdvancedRefundAmountEUR] =
  // useState("");
  const [selectedClaimDate, setSelectedClaimDate] = useState("");
  // const [selectedNotificationDate, setSelectedNotificationDate] = useState("");
  const [selectedReimbursementDate, setSelectedReimbursementDate] = useState("");
  // const [selectedPaymentDate, setSelectedPaymentDate] = useState("");
  const [selectedBankAccount, setSelectedBankAccount] = useState(() => {
    return viewClaimDialog?.params?.payableBankAccountId;
  });
  // const [selectedBankAccountDelta, setSelectedBankAccountDelta] = useState("");
  const [selectedClaimPreview, setSelectedClaimPreview] = useState([]);

  const [invoiceCount, setInvoiceCount] = useState(0);
  const [totalGrossAmount, setTotalGrossAmount] = useState(0);
  const [totalVATAmount, setTotalVATAmount] = useState(0);
  const [missingInvoiceCopies, setMissingInvoiceCopies] = useState(0);
  const [gridTotalGrossAmount, setGridTotalGrossAmount] = useState(0);
  const [gridTotalVATAmount, setGridTotalVATAmount] = useState(0);

  // const [bankAccountsFiltered, setBankAccountsFiltered] = useState([]);
  // const [bankAccountsDelta, setBankAccountsDelta] = useState([]);

  // const [showPopup, setShowPopup] = useState(false);
  // const [openConfirmation, setOpenConfirmation] = useState(false);
  // const [responseMessage, setResponseMessage] = useState("");
  // const overlayStyle = {
  //   position: "fixed",
  //   top: 0,
  //   left: 0,
  //   width: "100%",
  //   height: "100%",
  //   backgroundColor: "rgba(0, 0, 0, 0.5)",
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   zIndex: 9999,
  // };

  // const popupStyle = {
  //   backgroundColor: "white",
  //   padding: "20px",
  //   borderRadius: "5px",
  //   boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
  //   textAlign: "center",
  // };

  // const [gridTotalVATAmountEur, setGridTotalVATAmountEur] = useState(0);

  // const [alertInfo, setAlertInfo] = useState({ message: "", type: "" });

  const gridRef = React.useRef();

  // const invoiceGridDataJson = JSON.stringify(invoiceGridData);

  // console.table(viewClaimDialog.params);

  const getPreviewData = useCallback(async () => {
    setLoading(true);
    if (!customerId) {
      return;
    }

    await axios.get("/api/v1/claims/claimView/" + customerId + "/" + selectedClaimId.current).then((response) => {
      setSelectedClaimPreview(response.data);
      // console.log("getPreviewData1");
      // setBankAccounts(apiUtils.useApiData("/api/v1/bankaccountsByEntityId/" + customerId));
      // Filter the invoices list based on claimCode and claimStateId
      // console.log("getPreviewData2");
      const filteredInvoices = response.data.customerInvoices.filter(
        (invoice) =>
          ((invoice.invoiceStateId === 1 || invoice.invoiceStateId === 3 || invoice.invoiceStateId === 9) &&
            invoice.claimId === response.data.claimId) ||
          ((invoice.invoiceStateId === 2 || invoice.invoiceStateId === 3 || invoice.invoiceStateId === 4) &&
            (invoice.claimId === null || invoice.claimId === 0) &&
            response.data.claimStateId === 1)
      );
      // console.log("getPreviewData");
      setPayableToDelta(response.data.paymentToDelta === 1 ? true : false);
      setSelectedBankAccount(response.data.payableBankAccountId);
      setInvoiceGridData(filteredInvoices);
      setLoading(false);
      // console.log("getPreviewData");
    }, 2000);
  }, [customerId, selectedClaimId]);

  const calculateTotals = useCallback(() => {
    // console.log("calculateTotals");
    if (invoiceGridData.length === 0) {
      return;
    }
    let invoiceCount = 0;
    let totalGrossAmount = 0;
    let totalVATAmount = 0;
    let missingInvoiceCopies = 0;
    let gridTotalGrossAmount = 0;
    let gridTotalVATAmount = 0;
    invoiceGridData.forEach((row) => {
      gridTotalGrossAmount += row.invoiceGrossAmount;
      gridTotalVATAmount += row.invoiceVATAmountLocalCurrency;
      if (row.invoiceStateId === 3) {
        invoiceCount++;
        totalGrossAmount += row.invoiceGrossAmount;
        totalVATAmount += row.invoiceVATAmountLocalCurrency;
      }
    });
    setInvoiceCount(invoiceCount);
    setTotalGrossAmount(totalGrossAmount);
    setTotalVATAmount(totalVATAmount);
    setMissingInvoiceCopies(missingInvoiceCopies);
    setGridTotalGrossAmount(gridTotalGrossAmount);
    setGridTotalVATAmount(gridTotalVATAmount);
  }, [invoiceGridData]);

  useEffect(() => {
    selectedClaimId.current = viewClaimDialog?.params?.claimId;
    getPreviewData();
    // calculateTotals();
  }, [viewClaimDialog, getPreviewData, dataVersion]);

  useEffect(() => {
    calculateTotals();
  }, [calculateTotals]);

  const [viewCustomerInvoiceDialog, setViewCustomerInvoiceDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    custInfo: [],
  });

  // console.table(invoiceGridData);

  var claimCode = selectedClaimPreview.claimCode ? selectedClaimPreview.claimCode : 0;
  var country = selectedClaimPreview.country ? selectedClaimPreview.country : 0;
  var year = selectedClaimPreview.year ? selectedClaimPreview.year : 0;
  var startMonth = selectedClaimPreview.startMonth ? selectedClaimPreview.startMonth : 0;
  var endMonth = selectedClaimPreview.endMonth ? selectedClaimPreview.endMonth : 0;
  // var paymentTypeId = selectedClaimPreview.paymentTypeId
  //   ? selectedClaimPreview.paymentTypeId
  //   : 0;
  // var claimStateId = selectedClaimPreview.claimStateId
  //   ? selectedClaimPreview.claimStateId
  //   : 0;
  // var initPayableBankAccountId = selectedClaimPreview.PayableBankAccountId
  //   ? selectedClaimPreview.PayableBankAccountId
  //   : 0;

  // console.log("PayableBankAccountId", PayableBankAccountId);

  // const isFirstRender = useRef(true);

  function formatToLocalDate(dateString) {
    if (!dateString) return "";

    const date = new Date(dateString);
    if (date < new Date("1970-01-01T00:00:00Z")) {
      return "00-00-0000";
    }

    return date.toLocaleDateString("nl-NL", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  }

  useEffect(() => {
    // setSelectedPaymentTypeId(selectedClaimPreview.paymentTypeId);
    setSelectedClaimStateId(selectedClaimPreview.claimStateId);
    setSelectedBankAccount(selectedClaimPreview.payableBankAccountId);
    // setSelectedLiters(selectedClaimPreview.liters);
    // setSelectedAdvancedRefundAmountEUR(
    //   selectedClaimPreview.advancedRefundAmountEUR
    // );
    setSelectedClaimDate(formatToLocalDate(selectedClaimPreview.claimDate));
    // setSelectedNotificationDate(
    //   moment(selectedClaimPreview.notificationDate).format("YYYY-MM-DD")
    // );

    function isValidDate(date) {
      const nonRealDates = ["0000-00-00", "01-01-1900", "01-01-1970", "00-00-0000", "01-01-0001", "0001-01-01", "0001-01-01T00:00:00"];
      return !nonRealDates.includes(date);
    }

    const claimCode = selectedClaimPreview?.claimCode || "";
    const lastTwoDigits = claimCode.slice(-2);
    let calculatedDate = null;

    if (lastTwoDigits === "00") {
      // calculatedDate = "N/A"; // Moet Notification date worden
      calculatedDate = formatToLocalDate(selectedClaimPreview.notificationDate);
      // console.log(selectedClaimPreview);
    } else if (lastTwoDigits === "01") {
      // calculatedDate = "N/A"; // Moet Pay Date worden checken ook in lijst
      calculatedDate = formatToLocalDate(selectedClaimPreview.payDate);
    } else if (lastTwoDigits === "10") {
      calculatedDate = "N/A";
    } else if (lastTwoDigits === "11") {
      calculatedDate = "Pre-Financed";
    } else {
      const date = formatToLocalDate(selectedClaimPreview.payDate);
      calculatedDate = isValidDate(date) ? date : "N/A";
    }
    setSelectedReimbursementDate(calculatedDate);
    // setSelectedPaymentDate(
    //   moment(selectedClaimPreview.payDate).format("YYYY-MM-DD")
    // );
    // setBankAccountsFiltered(
    //   bankAccounts.filter(
    //     (bankAccount) => bankAccount.entityId === parseInt(customerId)
    //   )
    // );
    // setBankAccountsDelta(
    //   bankAccounts.filter((bankAccount) => bankAccount.entityId === 999999)
    // );
  }, [selectedClaimPreview, customerId, claimCode]);

  // console.log("Test", selectedClaimPreview);

  // const handleInvoiceLabelUpdate = (params) => {
  //   const rowIndex = invoiceGridData.findIndex(
  //     (row) => row.customerInvoiceId === params.customerInvoiceId
  //   );
  //   invoiceGridData[rowIndex].invoiceLabelId = params.invoiceLabelId;
  // };

  // const handleInvoiceStateUpdate = (params) => {
  //   const rowIndex = invoiceGridData.findIndex(
  //     (row) => row.customerInvoiceId === params.customerInvoiceId
  //   );
  //   invoiceGridData[rowIndex].invoiceStateId = params.invoiceStateId;

  //   // Invoice to next claim
  //   // console.table(invoiceStateData);
  //   let invoiceStateId = invoiceStateData.find(
  //     (x) => x.invoiceStateShortCode === "TNC"
  //   ).invoiceStateId;
  //   if (params.invoiceStateId === invoiceStateId) {
  //   }
  //   // console.log(invoiceStateId);
  //   calculateTotals();
  // };

  const columns = [
    {
      field: "invoiceNumber",
      headerName: "Invoice Number",
      flex: 0.5,
      hide: false,
    },
    {
      field: "invoiceDate",
      headerName: "Invoice Date",
      flex: 0.4,
      valueFormatter: (params) => formatToLocalDate(params?.value),
    },
    {
      field: "supplierId",
      headerName: "Supplier",
      flex: 0.5,
      valueGetter: (params) => supplierData.find((supplier) => supplier.supplierId === params.row.supplierId)?.supplierName,
    },
    {
      field: "productTypeId",
      headerName: "Product",
      flex: 0.4,
      valueGetter: (params) =>
        productData.find((product) => product.productTypeId === params.row.claimProductTypeId)?.productShortDescription,
    },
    {
      field: "countryCode",
      headerName: "Country",
      flex: 0.3,
      valueGetter: (params) => countryData.find((country) => country.countryId === params.row.countryCode).countryCode,
    },
    {
      field: "currencyId",
      headerName: "Currency",
      flex: 0.3,
      valueGetter: (params) => currencyData.find((cur) => cur.currencyId === params.row.currencyId).isoCode,
    },
    {
      field: "invoiceVATAmountLocalCurrency",
      headerName: "Local VAT Amount",
      flex: 0.5,
      renderCell: (params) => (
        <div style={{ textAlign: "right", paddingRight: "5px" }}>
          {params.value.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      ),
      align: "right",
    },
    {
      field: "fileId",
      headerName: "Invoice PDF available",
      flex: 0.5,
      renderCell: (params) =>
        params.value > 0 ? (
          <CheckCircleOutlineIcon style={{ color: "green", margin: "0 auto" }} />
        ) : (
          <CheckCircleOutlineIcon style={{ color: "red", margin: "0 auto" }} />
        ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.3,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              onClick={(e) => {
                setViewCustomerInvoiceDialog({
                  isOpen: true,
                  downloadFile: true,
                  title: "View Invoice",
                  params: params.row,
                  custInfo: [{ customerId: customerId, customerNumber: customerNumber }],
                  subTitle: "Are you sure you want to manage " + params.row.contactName + "?",
                  handleFile: () => {
                    handleFile(updatedData);
                  },
                });
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const FooterRow = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 10,
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ fontWeight: "bold", marginRight: 10 }}>Total Gross Amount:</div>
          <div>
            {gridTotalGrossAmount.toLocaleString("nl-NL", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
          <div style={{ fontWeight: "bold", marginLeft: 20, marginRight: 10 }}>Total VAT Amount:</div>
          <div>
            {gridTotalVATAmount.toLocaleString("nl-NL", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        </div>
        <div>
          <GridFooter
          // pageSize={pageSize}
          // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          // rowsPerPageOptions={[15, 50, 100]}
          />
        </div>
      </div>
    );
  };

  var updatedData;

  const handleFile = (updatedData) => {};

  const getRowClassName = (params) => {
    const invoiceGrossAmount = params.row.invoiceGrossAmount;
    const invoiceStateId = params.row.invoiceStateId;

    if (invoiceStateId === 4) {
      return "highlight-row-removed";
    }

    if (invoiceStateId === 3) {
      return "highlight-row-ready-for-claim";
    }

    if (invoiceStateId === 2) {
      return "highlight-row-not-claimable";
    }

    if (invoiceStateId === 9) {
      return "highlight-row-rejected";
    }

    if (invoiceGrossAmount > 3000) {
      return "highlight-row";
    }

    return "";
  };

  // const handleClosePopup = () => {
  //   setShowPopup(false);
  // };

  // const handleClickOpenConfirmation = () => {
  //   setOpenConfirmation(true);
  // };

  // const handleCloseConfirmation = () => {
  //   setOpenConfirmation(false);
  // };

  // console.log("Selected Bank Account:", selectedBankAccount);

  const ColorLegend = () => (
    <Box display="flex" justifyContent="flex-end" alignItems="center" mt={2} mb={2}>
      <Typography variant="body2" style={{ fontSize: "0.8rem", marginRight: "10px" }}>
        Legend:
      </Typography>
      <Box display="flex" alignItems="center" mr={2}>
        <Box width={15} height={15} bgcolor="#c8e6c9" mr={0.5} />
        <Typography variant="body2" style={{ fontSize: "0.8rem" }}>
          OK
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" mr={2}>
        <Box width={15} height={15} bgcolor="#fae7e7" mr={0.5} />
        <Typography variant="body2" style={{ fontSize: "0.8rem" }}>
          Rejected
        </Typography>
      </Box>
      {/* <Box display="flex" alignItems="center">
        <Box width={15} height={15} bgcolor="#a5d8ff" mr={0.5} />
        <Typography variant="body2" style={{ fontSize: "0.8rem" }}>
          No Status (Pastel Blue)
        </Typography>
      </Box> */}
    </Box>
  );

  return (
    <div>
      {/* {showPopup && (
        <div style={overlayStyle}>
          <div style={popupStyle}>
            {responseMessage ? (
              <>
                <p>{responseMessage}</p>
                <p>
                  <button
                    onClick={handleClosePopup}
                    style={{
                      border: "none",
                      background: "none",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Close
                  </button>
                </p>
              </>
            ) : (
              <p>Please wait, upload in progress...</p>
            )}
          </div>
        </div>
      )} */}
      <Dialog
        open={viewClaimDialog.isOpen}
        //   onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle id="alert-dialog-title">{viewClaimDialog.title}</DialogTitle>
        <Divider />
        <DialogContent>
          <Card mb={6}>
            <CardContent>
              {/* {alertInfo.message && (
                <Alert severity={alertInfo.type} my={3}>
                  {alertInfo.message}
                </Alert>
              )} */}

              <div>
                <Grid container xs={12} sx={{ mb: 5 }}>
                  <Grid container xs={6} spacing={1} sx={{ pr: 2 }}>
                    <Grid item xs={5}>
                      <Typography>Claim Code:</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      {claimCode}
                    </Grid>
                    <Grid item xs={5}>
                      <Typography>Year:</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      {year}
                    </Grid>
                    <Grid item xs={5}>
                      <Typography>Period:</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      {startMonth.toString().padStart(2, "0")} / {endMonth.toString().padStart(2, "0")}
                    </Grid>
                    <Grid item xs={5}>
                      <Typography>Country:</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      {countryData.find((country1) => country1.countryId === country)?.countryCode}
                    </Grid>
                    <Grid item xs={5}>
                      <Typography>Claim State:</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Tooltip
                        title={
                          <React.Fragment>
                            Claim state information
                            <ul>
                              <li>Pending: Claim submitted to Tax Authorities, awaiting for being processed</li>
                              <li>Finished: Claim processed by foreign Tax Authorities, decision had been made</li>
                              <li>(Partly) Rejected Claims: (part of) Claim has been rejected</li>
                            </ul>
                          </React.Fragment>
                        }
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {(() => {
                            let displayText = "";
                            switch (selectedClaimStateId) {
                              case 1:
                                displayText = "Collecting";
                                break;
                              case 3:
                                displayText = "In progress";
                                break;
                              case 4:
                                displayText = "Pending";
                                break;
                              case 16:
                                if (selectedClaimPreview.hasRejectedInvoices) {
                                  displayText = "(Partly) Rejected";
                                } else {
                                  displayText = "Finished";
                                }

                                break;
                              default:
                                displayText = "";
                            }
                            return displayText;
                          })()}
                          <InfoIcon
                            sx={{
                              color: "#376FD0",
                              ml: 1,
                              fontSize: 16,
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </Tooltip>
                    </Grid>

                    <Grid item xs={5}>
                      <Typography>Claim Date:</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      {selectedReimbursementDate === "Pre-Financed" ? (
                        <Tooltip title="Due to pre-financing, the claim date is related to the individual invoices. Please look at the individual invoices to see when the payment took place.">
                          <div style={{ display: "flex", alignItems: "center" }}>
                            N/A
                            <InfoIcon
                              sx={{
                                color: "#376FD0",
                                ml: 1,
                                fontSize: 16,
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </Tooltip>
                      ) : (
                        selectedClaimDate
                      )}
                    </Grid>
                    {/* <Grid item xs={5}>
                      <Typography>Notification Date:</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Tooltip title="Notification date information">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {selectedNotificationDate}
                          <InfoIcon
                            sx={{
                              color: "#376FD0",
                              ml: 1,
                              fontSize: 16,
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </Tooltip>
                    </Grid> */}
                    <Grid item xs={5}>
                      <Typography>Refund/Payout Date:</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      {selectedReimbursementDate === "N/A" ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          N/A
                          <InfoIcon
                            sx={{
                              color: "#376FD0",
                              ml: 1,
                              fontSize: 16,
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      ) : selectedReimbursementDate === "Pre-Financed" ? (
                        <Tooltip title="Due to pre-financing, the payout date is related to the individual invoices. Please look at the individual invoices to see when the payment took place.">
                          <div style={{ display: "flex", alignItems: "center" }}>
                            {selectedReimbursementDate}
                            <InfoIcon
                              sx={{
                                color: "#376FD0",
                                ml: 1,
                                fontSize: 16,
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </Tooltip>
                      ) : (
                        <Tooltip title="In case the refund gets requested directly on the bank account of the customer (you), the actual Payout Date may differ a few days/weeks.">
                          <div style={{ display: "flex", alignItems: "center" }}>
                            {selectedReimbursementDate}
                            <InfoIcon
                              sx={{
                                color: "#376FD0",
                                ml: 1,
                                fontSize: 16,
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </Tooltip>
                      )}
                    </Grid>

                    {/* <Grid item xs={5}>
                      <Typography>Pay Date:</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      {selectedPaymentDate}
                    </Grid> */}
                    <Grid item xs={5}>
                      <Typography>Payable Bank Account:</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      {!payableToDelta
                        ? bankAccounts.find((ba) => ba.bankAccountId === selectedBankAccount)?.iban
                        : "Payable to Delta"}
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid item xs={12} sx={{ p: 5, pt: 3 }} backgroundColor="#376fd0">
                      <Grid container>
                        <Grid item xs={5}>
                          <Typography sx={{ fontSize: 22, color: "white" }}>Invoice Count:</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography
                            sx={{
                              fontSize: 22,
                              color: "white",
                              textAlign: "right",
                            }}
                          >
                            {invoiceCount}
                          </Typography>
                        </Grid>
                        <Grid item xs={5} />
                      </Grid>
                      <Grid container>
                        <Grid item xs={5}>
                          <Typography sx={{ fontSize: 22, color: "white" }}>Total Gross Amount:</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography
                            sx={{
                              fontSize: 22,
                              color: "white",
                              textAlign: "right",
                            }}
                          >
                            {(totalGrossAmount &&
                              totalGrossAmount.toLocaleString("nl-NL", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })) ||
                              "0,00"}
                          </Typography>
                        </Grid>
                        <Grid item xs={5} />
                      </Grid>
                      <Grid container>
                        <Grid item xs={5}>
                          <Typography sx={{ fontSize: 22, color: "white" }}>Total VAT Amount:</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography
                            sx={{
                              fontSize: 22,
                              color: "white",
                              textAlign: "right",
                            }}
                          >
                            {(totalVATAmount &&
                              totalVATAmount.toLocaleString("nl-NL", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })) ||
                              "0,00"}
                          </Typography>
                        </Grid>
                        <Grid item xs={5} />
                      </Grid>
                      <Grid container>
                        <Grid item xs={5}>
                          <Typography sx={{ fontSize: 22, color: "white" }}>Missing Invoice Copies:</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography
                            sx={{
                              fontSize: 22,
                              color: "white",
                              textAlign: "right",
                            }}
                          >
                            {missingInvoiceCopies}
                          </Typography>
                        </Grid>
                        <Grid item xs={5} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <ColorLegend />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: 20,
                      backgroundColor: "#376fd0",
                      color: "white",
                    }}
                  ></div>
                  <ViewCustomerInvoiceDialog
                    viewCustomerInvoiceDialog={viewCustomerInvoiceDialog}
                    setViewCustomerInvoiceDialog={setViewCustomerInvoiceDialog}
                    countryArr={countryData}
                    supplierArr={supplierData}
                    currencyArr={currencyData}
                    productTypeArr={productData}
                    refreshGrid={getPreviewData}
                  />
                  {loading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "500px",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    <DataGrid
                      getRowId={(row) => row.customerInvoiceId}
                      disableSelectionOnClick
                      disableColumnSelector
                      components={{ Footer: FooterRow, Toolbar: GridToolbar }}
                      density="compact"
                      componentsProps={{
                        toolbar: {
                          csvOptions: { disableToolbarButton: true },
                          printOptions: { disableToolbarButton: true },
                          showQuickFilter: true,
                          quickFilterProps: { debounceMs: 250 },
                        },
                      }}
                      pagination
                      paginationMode="client"
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                      rowsPerPageOptions={[15, 50, 100]}
                      rows={invoiceGridData}
                      columns={columns}
                      ref={gridRef}
                      getRowClassName={getRowClassName}
                      sx={{
                        height: 500,
                        width: "100%",
                        borderRadius: 1,
                        backgroundColor: "background.paper",
                        boxShadow: 2,
                        "& .MuiDataGrid-cell:hover": {
                          color: "primary.main",
                        },
                        marginTop: 0,
                        ".highlight-row": {
                          bgcolor: "#ffeeba",
                        },
                        ".highlight-row-removed": {
                          bgcolor: "#fae7e7",
                        },
                        ".highlight-row-ready-for-claim": {
                          bgcolor: "#c8e6c9",
                        },
                        ".highlight-row-rejected": {
                          bgcolor: "#fae7e7",
                        },
                        ".highlight-row-not-claimable": {
                          bgcolor: "#d7ccc8",
                        },
                      }}
                    />
                  )}
                </Grid>
              </div>
              <Grid item container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setViewClaimDialog({
                        ...viewClaimDialog,
                        isOpen: false,
                      });
                      setInvoiceGridData([]);
                      setSelectedClaimPreview([]);
                    }}
                    sx={{ marginTop: 3 }}
                  >
                    Close
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
    </div>
  );
}
