import React, { useState, useEffect, useCallback, useContext } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { DataGrid, GridFooter, GridToolbar } from "@mui/x-data-grid";
import {
  Grid,
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography,
  Divider,
  IconButton,
  Button,
  CircularProgress,
  Tabs,
  Tab,
  Tooltip,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { spacing } from "@mui/system";
import axios from "axios";
import ViewCustomerInvoiceDialog from "../../components/ViewCustomerInvoiceDialog";
import moment from "moment";
import SelectWrapper from "../../components/SelectBoxDataGridCell";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DataGridExport from "../../components/DataGridExport";
import ConfirmDialog from "../../components/ConfirmDialog";
// import { useMsal } from "@azure/msal-react";
import ViewClaimDialog from "../../components/ViewClaimDialog";
import apiUtils from "../../utils/apiUtils";
import InfoIcon from "@mui/icons-material/InfoOutlined";
// import { formatDate } from "date-fns";
import { GlobalContext } from "../../contexts/GlobalContext";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const CustomerInvoices = (props) => {
  const [pageSize, setPageSize] = useState(15);
  const [activeTab, setActiveTab] = useState(0); // 0 for 'All Invoices' tab
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const supplierData = apiUtils.useApiData("/api/v1/suppliers");
  const countryData = apiUtils.useApiData("/api/v1/countries");
  const productData = apiUtils.useApiData("/api/v1/productTypes");
  const currencyData = apiUtils.useApiData("/api/v1/currencies");
  const invoiceLabelData = apiUtils.useApiData("/api/v1/invoiceLabels");
  const invoiceStateData = apiUtils.useApiData("/api/v1/invoiceStates");
  const { customerId, customerNumber } = useContext(GlobalContext);
  const [selectedInvoiceCount, setSelectedInvoiceCount] = useState(0);
  const [selectedInvoiceTotalVATAmount, setSelectedInvoiceTotalVATAmount] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    getPreviewData();
  };

  const [viewClaimDialog, setViewClaimDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    custInfo: [],
  });

  var updatedData;

  const [claimData, setClaimData] = useState([]);
  const [customerPreFinanceData, setCustomerPreFinanceData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (!customerId) {
        return;
      }

      try {
        const [claimsResponse, preFinanceResponse] = await Promise.all([
          axios.get("/api/v1/claimsByEntityId/" + customerId),
          axios.get("/api/v1/preFinanceByEntityId/" + customerId),
        ]);

        setClaimData(claimsResponse.data);
        setCustomerPreFinanceData(preFinanceResponse.data);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    }

    fetchData();
  }, [customerId]);

  const updateTableDataWithExportableData = useCallback(
    (originalData) => {
      const updatedData = originalData.map((invoice) => {
        const selectedCountry = countryData.find((country) => country.countryId === invoice.countryCode);
        const selectedCurrency = currencyData.find((currency) => currency.currencyId === selectedCountry?.currency);
        const claimOfInvoice = claimData.find((claim) => claim.claimId === invoice.claimId);
        const invoiceLabel = invoiceLabelData.find((invoiceLabel) => invoiceLabel.invoiceLabelId === invoice.invoiceLabelId);
        const supplier = supplierData.find((supplier) => supplier.supplierId === invoice.supplierId);
        const prefinancedFound = customerPreFinanceData.find((prefinanced) => prefinanced.preFinanceId === invoice.preFinanceId);
        const invoiceStatus = invoiceStateData.find((invoiceStatus) => invoiceStatus.invoiceStateId === invoice.invoiceStateId);
        const vatAmount = invoice.invoiceVATAmountLocalCurrency;

        const formattedInvoiceDate = invoice.invoiceDate.split("T")[0];
        const countryCode = selectedCountry ? selectedCountry.countryCode : "";
        const currencyCode = selectedCurrency ? selectedCurrency.isoCode : "";
        const claimCode = claimOfInvoice ? claimOfInvoice.claimCode : "";
        const invoiceLabelDesc = invoiceLabel ? invoiceLabel.invoiceLabelDescription : "";
        const invoiceStatusDesc = prefinancedFound
          ? "Pre-Financed"
          : invoiceStatus && invoiceStatus.invoiceStateId !== 3
          ? invoiceStatus.invoiceStateDescription
          : invoiceStatus && invoiceStatus.invoiceStateId === 3 && claimOfInvoice
          ? claimOfInvoice.claimStateId === 4
            ? "Pending"
            : claimOfInvoice.claimStateId === 16
            ? "Reimbursed"
            : ""
          : "";

        const supplierName = supplier ? supplier.supplierName : "";
        const preFinanced = prefinancedFound ? 1 : 0;
        const formattedPreFinancedDate =
          prefinancedFound && prefinancedFound.payoutDate && prefinancedFound.payoutDate > "01-01-1970"
            ? new Date(prefinancedFound.payoutDate).toLocaleDateString("nl-NL", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })
            : "";

        return {
          ...invoice,
          currencyCode: currencyCode,
          countryCode2: countryCode,
          invoiceDate: formattedInvoiceDate,
          claimCode: claimCode,
          invoiceLabelDesc: invoiceLabelDesc,
          supplierName: supplierName,
          preFinanced: preFinanced,
          preFinancedDate: formattedPreFinancedDate,
          vatAmount: vatAmount,
          invoiceStatusDesc: invoiceStatusDesc,
        };
      });

      return updatedData;
    },
    [countryData, supplierData, currencyData, claimData, customerPreFinanceData, invoiceLabelData, invoiceStateData]
  );

  const getFilteredData = () => {
    switch (activeTab) {
      case 0: // All Invoices
        const filteredDataTab0 = tableData.filter((invoice) => invoice.invoiceStateId > 1); //Exclude Not Processed Yet
        return updateTableDataWithExportableData(filteredDataTab0);
      case 1: // Pending Invoices
        const filteredDataTab1 = tableData.filter((invoice) =>
          claimData.some((claim) => claim.claimId === invoice.claimId && claim.claimStateId === 4)
        );
        return updateTableDataWithExportableData(filteredDataTab1);
      case 2: // Reimbursed Invoices
        const filteredDataTab2 = tableData.filter((invoice) =>
          claimData.some((claim) => claim.claimId === invoice.claimId && claim.claimStateId === 16 && invoice.invoiceStateId !== 9)
        );
        return updateTableDataWithExportableData(filteredDataTab2);
      case 3: // Pre-Financed Invoices
        const filteredDataTab3 = tableData.filter((invoice) => invoice.invoiceStatusDesc === "Pre-Financed");
        return updateTableDataWithExportableData(filteredDataTab3);
      case 4: // Not Claimable Invoices
        const filteredDataTab4 = tableData.filter((invoice) => claimData.some((claim) => invoice.invoiceStateId === 4));
        return updateTableDataWithExportableData(filteredDataTab4);
      case 5: // Rejected Invoices
        const filteredDataTab5 = tableData.filter((invoice) =>
          claimData.some((claim) => claim.claimId === invoice.claimId && claim.claimStateId === 16 && invoice.invoiceStateId === 9)
        );
        return updateTableDataWithExportableData(filteredDataTab5);
      default: // All Invoices
        return updateTableDataWithExportableData(tableData);
    }
  };

  const [selectedCountry, setSelectedCountry] = useState("any");
  const [selectedYear, setSelectedYear] = useState("any");
  const [selectedStartMonth, setSelectedStartMonth] = useState("any");
  const [selectedEndMonth, setSelectedEndMonth] = useState("any");

  const handleFile = (updatedData) => {};

  const fetchCustomerInvoices = useCallback(async () => {
    try {
      const response = await axios.get("/api/v1/customerInvoicesByEntityId/" + customerId);

      // Update the original data with currencyCode
      const originalData = response.data;
      const updatedData = updateTableDataWithExportableData(originalData);

      setTableData(updatedData);
    } catch (error) {
      console.error("Error fetching customer invoices by entity ID:", error);
    }
  }, [customerId, updateTableDataWithExportableData]);

  useEffect(() => {
    if (!customerId) {
      return;
    }
    let isCancelled = false;

    const fetchAsync = async () => {
      try {
        const response = await axios.get("/api/v1/customerInvoicesByEntityId/" + customerId);
        if (!isCancelled) {
          const originalData = response.data;
          const updatedData = updateTableDataWithExportableData(originalData);
          setTableData(updatedData);
        }
      } catch (error) {
        console.error("Error fetching customer invoices by entity ID:", error);
      }
    };

    fetchAsync();

    return () => {
      isCancelled = true;
    };
  }, [fetchCustomerInvoices, customerId, updateTableDataWithExportableData]);

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => {}, // Empty function for now, will be updated when needed
  });

  const [viewCustomerInvoiceDialog, setViewCustomerInvoiceDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    custInfo: [],
  });

  function onEditConfirmed(e, params) {
    window.location.reload(false);
    setViewCustomerInvoiceDialog({
      ...viewCustomerInvoiceDialog,
      isOpen: false,
    });
  }

  const handleEditCellChange = (params) => {
    const updatedRows = tableData.map((row) => (row.id === params.id ? { ...row, [params.field]: params.props.value } : row));
    setTableData(updatedRows);
  };

  const getPreviewData = useCallback(async () => {
    if (!customerId) {
      return;
    }
    setLoading(true);
    await axios.get("/api/v1/customerInvoicesByEntityId/" + customerId).then((response) => {
      const invoicesReturn = response.data;
      let invoicesFiltered = invoicesReturn;
      if (selectedCountry !== "any") {
        invoicesFiltered = invoicesFiltered.filter((inv) => inv.countryCode === selectedCountry);
      }
      if (selectedYear !== "any") {
        invoicesFiltered = invoicesFiltered.filter((inv) => {
          const invYear = new Date(inv.invoiceDate).getFullYear();
          return invYear === selectedYear;
        });
      }
      if (selectedYear !== "any" && selectedStartMonth !== "any" && selectedEndMonth !== "any") {
        invoicesFiltered = invoicesFiltered.filter((inv) => {
          const invYear = new Date(inv.invoiceDate).getFullYear();
          const invMonth = new Date(inv.invoiceDate).getMonth();
          const filterStartDate = new Date(selectedYear, selectedStartMonth - 1, 1);
          const filterEndDate = new Date(selectedYear, selectedEndMonth, 0);
          const invoiceDate = new Date(invYear, invMonth, 1);
          return invoiceDate >= filterStartDate && invoiceDate <= filterEndDate;
        });
      }

      const originalData = invoicesFiltered;
      const updatedData = updateTableDataWithExportableData(originalData);

      setTableData(updatedData);
      setLoading(false);
    }, 2000);
  }, [customerId, selectedCountry, selectedYear, selectedStartMonth, selectedEndMonth, updateTableDataWithExportableData]);

  function generateArrayOfYears() {
    var max = new Date().getFullYear() + 1;
    var min = max - 5;
    var years = [];
    for (var i = max; i >= min; i--) {
      years.push({ yearId: i, yearText: i });
    }

    return years;
  }

  let yearArr = generateArrayOfYears().reverse();

  const monthArr = [
    { monthId: 1, monthText: "Jan" },
    { monthId: 2, monthText: "Feb" },
    { monthId: 3, monthText: "Mar" },
    { monthId: 4, monthText: "Apr" },
    { monthId: 5, monthText: "May" },
    { monthId: 6, monthText: "Jun" },
    { monthId: 7, monthText: "Jul" },
    { monthId: 8, monthText: "Aug" },
    { monthId: 9, monthText: "Sep" },
    { monthId: 10, monthText: "Oct" },
    { monthId: 11, monthText: "Nov" },
    { monthId: 12, monthText: "Dec" },
  ];

  const handleCountryValue = (value, name) => {
    setSelectedCountry(value);
  };
  const handleYearValue = (value, name) => {
    setSelectedYear(value);
  };
  const handleStartMonthValue = (value, name) => {
    setSelectedStartMonth(value);
  };
  const handleEndMonthValue = (value, name) => {
    setSelectedEndMonth(value);
  };

  // const handleFilter = async () => {
  //   getPreviewData();
  // };

  useEffect(() => {
    getPreviewData();
  }, [selectedCountry, selectedYear, getPreviewData]);

  const handleResetFilter = async () => {
    setSelectedCountry("any");
    setSelectedYear("any");
    setSelectedStartMonth("any");
    setSelectedEndMonth("any");
    getPreviewData();
    // try {
    //   const response = await axios.get(
    //     "/api/v1/customerInvoicesByEntityId/" + customerId
    //   );
    //   const originalData = response.data;
    //   const updatedData = updateTableDataWithExportableData(originalData);
    //   setTableData(updatedData);
    // } catch (error) {
    //   console.error("Error fetching customer invoices by entity ID:", error);
    // }
  };

  const BulkEditFooter = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          borderTop: "1px solid #ccc",
          padding: 10,
        }}
      >
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ fontWeight: "bold", marginBottom: 5 }}>Total Selected Invoices:</div>
            <div style={{ marginBottom: 5 }}>{selectedInvoiceCount}</div>
          </div>
          {/* <div style={{ display: "flex", flexDirection: "column", marginLeft: 20 }}>
            <div style={{ fontWeight: "bold", marginBottom: 5 }}>Total Gross Amount:</div>
            <div style={{ marginBottom: 5 }}>
              {selectedInvoiceTotalAmount.toLocaleString("nl-NL", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div> */}
          <div style={{ display: "flex", flexDirection: "column", marginLeft: 20 }}>
            <div style={{ fontWeight: "bold", marginBottom: 5 }}>Total VAT Amount:</div>
            <div style={{ marginBottom: 5 }}>
              {selectedInvoiceTotalVATAmount.toLocaleString("nl-NL", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>
          {/* <div
            style={{ display: "flex", flexDirection: "column", marginLeft: 20 }}
          >
            <div style={{ fontWeight: "bold", marginBottom: 5 }}>
              Total VAT Amount EUR:
            </div>
            <div style={{ marginBottom: 5 }}>
              {selectedInvoiceTotalVATAmountEUR.toLocaleString("nl-NL", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div> */}
        </div>
        <div>
          <div>
            <GridFooter
              style={{ borderTop: "none" }}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[15, 50, 100]}
            />
          </div>
        </div>
      </div>
    );
  };

  const fetchClaimsByEntityId = () => {};

  // Utility function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const columns = [
    // {
    //   field: "customerInvoiceId",
    //   headerName: "id",
    //   flex: 0.5,
    //   hide: true,
    // },
    {
      field: "invoiceNumber",
      headerName: "Invoice Number",
      flex: 0.7,
    },
    {
      field: "claimCode",
      headerName: "Claim Code",
      flex: 0.65,
      renderCell: (params) => {
        const { invoiceStateId, invoiceVATAmountLocalCurrency, claimId } = params.row;
        let invoiceClaimData = claimData.find((claim) => claim.claimId === claimId);
        // console.log("invoiceClaimData", params.row);
        if (
          invoiceStateId === 4 ||
          invoiceVATAmountLocalCurrency === 0 ||
          invoiceClaimData?.claimStateId === 1 ||
          invoiceClaimData?.claimStateId === 3
        ) {
          return ""; // Return an empty string when conditions are met
        }

        return (
          <Button
            variant="text"
            onClick={() => {
              setViewClaimDialog({
                ...viewClaimDialog,
                isOpen: true,
                title: "View Claim",
                params: params.row, // Pass the row data to the dialog
                custInfo: [{ customerId: customerId, customerNumber: customerNumber }],
              });
            }}
          >
            {params.value}
          </Button>
        );
      },
    },

    {
      field: "invoiceDate",
      headerName: "Invoice Date",
      flex: 0.45,
      valueFormatter: (params) => moment(params?.value).format("DD-MM-YYYY"),
    },
    {
      field: "invoiceStatusDesc",
      headerName: "Invoice State",
      flex: 0.7,
      valueFormatter: (params) => {
        // If the value is null, return an empty string for display
        return params.value || "";
      },
    },
    {
      field: "invoiceLabelDesc",
      headerName: "Invoice Label",
      flex: 0.8,
      valueFormatter: (params) => {
        // If the value is null, return an empty string for display
        return params.value || "";
      },
    },
    {
      field: "supplierName",
      headerName: "Supplier",
      flex: 1,
    },
    {
      field: "countryCode2",
      headerName: "Country",
      flex: 0.3,
    },
    {
      field: "currencyCode",
      headerName: "Currency",
      flex: 0.35,
    },
    {
      field: "vatAmount",
      headerName: "VAT Amount",
      flex: 0.45,
      align: "right",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            paddingRight: "15px",
          }}
        >
          {params.value.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      ),
    },
    {
      field: "preFinanced",
      headerName: "Pre-Financed",
      flex: 0.45,
      renderCell: (params) => (params.value > 0 ? <CheckCircleOutlineIcon style={{ color: "green", margin: "0 auto" }} /> : ""),
    },
    {
      field: "preFinancedDateSortable",
      headerName: "Payout Date",
      width: 100,
      sortingOrder: ["asc", "desc"],
      valueGetter: (params) => {
        const nonRealDates = [
          "0000-00-00",
          "01-01-1900",
          "01-01-1970",
          "00-00-0000",
          "01-01-0001",
          "0001-01-01",
          "01-01-1",
          "0001-01-01T00:00:00",
        ];
        const { invoiceStateId, invoiceVATAmountLocalCurrency, claimId, preFinanceId } = params.row;
        let invoiceClaimData = claimData.find((claim) => claim.claimId === claimId);
        if (
          invoiceStateId === 4 ||
          invoiceVATAmountLocalCurrency === 0 ||
          invoiceClaimData?.claimStateId === 1 ||
          invoiceClaimData?.claimStateId === 3
        ) {
          return Number.MAX_SAFE_INTEGER;
        }
        if (invoiceClaimData) {
          const rowData = tableData.find((data) => data.claimId === invoiceClaimData.claimId);
          const lastTwoDigits = invoiceClaimData.claimCode.slice(-2);
          if (lastTwoDigits === "11") {
            if (rowData?.preFinanceId > 0) {
              let preFinanceData = customerPreFinanceData.find((data) => data.preFinanceId === rowData.preFinanceId);
              if (preFinanceData && preFinanceData.payoutDate) {
                const ts = new Date(preFinanceData.payoutDate).getTime();
                return isNaN(ts) ? Number.MAX_SAFE_INTEGER : ts;
              }
              return Number.MAX_SAFE_INTEGER;
            } else {
              return Number.MAX_SAFE_INTEGER;
            }
          }
          if (lastTwoDigits === "01") {
            if (nonRealDates.includes(invoiceClaimData.payDate)) {
              return Number.MAX_SAFE_INTEGER;
            } else {
              const ts = new Date(invoiceClaimData.payDate).getTime();
              return isNaN(ts) ? Number.MAX_SAFE_INTEGER : ts;
            }
          }
          if (lastTwoDigits === "10") {
            return Number.MAX_SAFE_INTEGER;
          }
          if (lastTwoDigits === "00") {
            if (invoiceClaimData?.claimStateId === 4 && preFinanceId === 0) {
              return Number.MAX_SAFE_INTEGER;
            }
            const ts = new Date(invoiceClaimData.reimbursementDate).getTime();
            return isNaN(ts) ? Number.MAX_SAFE_INTEGER : ts;
          }
        }
        return Number.MAX_SAFE_INTEGER;
      },
      sortComparator: (a, b) => a - b,
      renderCell: (params) => {
        const nonRealDates = [
          "0000-00-00",
          "01-01-1900",
          "01-01-1970",
          "00-00-0000",
          "01-01-0001",
          "0001-01-01",
          "01-01-1",
          "0001-01-01T00:00:00",
        ];
        const { invoiceStateId, invoiceVATAmountLocalCurrency, claimId, preFinanceId } = params.row;
        let invoiceClaimData = claimData.find((claim) => claim.claimId === claimId);
        if (
          invoiceStateId === 4 ||
          invoiceVATAmountLocalCurrency === 0 ||
          invoiceClaimData?.claimStateId === 1 ||
          invoiceClaimData?.claimStateId === 3
        ) {
          return "";
        }
        if (invoiceClaimData) {
          const rowData = tableData.find((data) => data.claimId === invoiceClaimData.claimId);
          const lastTwoDigits = invoiceClaimData.claimCode.slice(-2);
          if (lastTwoDigits === "11") {
            if (rowData?.preFinanceId > 0) {
              let preFinanceData = customerPreFinanceData.find((data) => data.preFinanceId === rowData.preFinanceId);
              if (preFinanceData && preFinanceData.payoutDate) {
                return formatDate(preFinanceData.payoutDate);
              }
              return "N/A";
            } else {
              return "N/A";
            }
          }
          if (lastTwoDigits === "01") {
            if (nonRealDates.includes(invoiceClaimData.payDate)) {
              return "";
            } else {
              return formatDate(invoiceClaimData.payDate);
            }
          }
          if (lastTwoDigits === "10") {
            return "N/A";
          }
          if (lastTwoDigits === "00") {
            if (invoiceClaimData?.claimStateId === 4 && preFinanceId === 0) {
              return "";
            }
            const formattedDate = formatDate(invoiceClaimData.reimbursementDate);
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                {formattedDate}
                <Tooltip title="Date of decision on claim, pay-out may differ a few days/weeks." enterTouchDelay={0} placement="top">
                  <InfoIcon sx={{ color: "#376FD0", marginLeft: 1, fontSize: 16, cursor: "pointer" }} />
                </Tooltip>
              </div>
            );
          }
        } else {
          return "";
        }
      },
    },
    // {
    //   field: "fileId",
    //   headerName: "PDF Available",
    //   flex: 0.4,
    //   renderCell: (params) =>
    //     params.value > 0 ? (
    //       <CheckCircleOutlineIcon
    //         style={{ color: "green", margin: "0 auto" }}
    //       />
    //     ) : (
    //       <CheckCircleOutlineIcon style={{ color: "red", margin: "0 auto" }} />
    //     ),
    // },
    {
      field: "actions",
      headerName: "View",
      flex: 0.3,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              onClick={(e) => {
                setViewCustomerInvoiceDialog({
                  isOpen: true,
                  downloadFile: true,
                  title: "View Invoice",
                  params: params.row,
                  custInfo: [{ customerId: customerId, customerNumber: customerNumber }],
                  subTitle: "Are you sure you want to manage " + params.row.contactName + "?",
                  onConfirm: () => {
                    onEditConfirmed(e, params.row);
                  },
                  handleFile: () => {
                    handleFile(updatedData);
                  },
                });
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(newSelection);
    // let selectedRowsAmount = 0;
    let selectedRowsVATAmount = 0;
    // let disablePreFinanced = false;

    newSelection.forEach((rowId) => {
      const selectedRow = tableData.find((row) => row.customerInvoiceId === rowId);
      if (selectedRow) {
        // selectedRowsAmount += selectedRow.invoiceGrossAmount;
        selectedRowsVATAmount += selectedRow.invoiceVATAmountLocalCurrency;

        // Check if preFinancedId is greater than 0
        // if (selectedRow.preFinanceId > 0) {
        //   disablePreFinanced = true;
        // }
      }
    });

    setSelectedInvoiceCount(newSelection.length);
    // setSelectedInvoiceTotalAmount(selectedRowsAmount);
    setSelectedInvoiceTotalVATAmount(selectedRowsVATAmount);

    // Update the button's disabled state
    // setIsPreFinancedDisabled(disablePreFinanced);
  };

  return (
    <React.Fragment>
      <ViewClaimDialog
        viewClaimDialog={viewClaimDialog}
        setViewClaimDialog={setViewClaimDialog}
        refreshTable={fetchClaimsByEntityId}
      />
      <Tabs value={activeTab} onChange={handleTabChange} aria-label="Invoice Tabs">
        <Tab label="All Invoices" sx={{ typography: "h6", fontWeight: "bold" }} />
        <Tab label="Pending Invoices" sx={{ typography: "h6", fontWeight: "bold" }} />
        <Tab label="Reimbursed Invoices" sx={{ typography: "h6", fontWeight: "bold" }} />
        <Tab label="Pre-Financed Invoices" sx={{ typography: "h6", fontWeight: "bold" }} />
        <Tab label="Not Claimable Invoices" sx={{ typography: "h6", fontWeight: "bold" }} />
        <Tab label="Rejected Invoices" sx={{ typography: "h6", fontWeight: "bold" }} />
      </Tabs>

      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
      <Helmet title="Customer Invoices" />

      <Divider my={6} />
      <Grid item xs={12} md={6}></Grid>
      <Card mb={0}>
        <CardContent
          pb={1}
          sx={{
            marginBottom: 0,
          }}
        ></CardContent>
        <Grid container spacing={0} mt={0}>
          {/* <Grid container xs={12} sx={{ mb: 5 }}> */}
          <Grid container xs={12} spacing={5} sx={{ pr: 2 }}>
            <Grid item xs={2}>
              <SelectWrapper
                name="countryId"
                labelColor="#f64435"
                label="Country"
                options={[
                  { key: "any", value: "Any" },
                  ...JSON.parse(
                    JSON.stringify(JSON.parse(JSON.stringify(countryData).split('"countryId":').join('"key":')))
                      .split('"countryCode":')
                      .join('"value":')
                  ),
                ]}
                value={selectedCountry}
                handleChange={handleCountryValue}
              />
            </Grid>
            <Grid item xs={2}>
              <SelectWrapper
                name="year"
                label="Year"
                // value={year}
                options={[
                  { key: "any", value: "Any" },
                  ...JSON.parse(
                    JSON.stringify(JSON.parse(JSON.stringify(yearArr).split('"yearId":').join('"key":')))
                      .split('"yearText":')
                      .join('"value":')
                  ),
                ]}
                value={selectedYear}
                handleChange={handleYearValue}
              />
            </Grid>
            <Grid item xs={2}>
              <SelectWrapper
                name="startMonth"
                label="Start month"
                options={[
                  { key: "any", value: "Any" },
                  ...JSON.parse(
                    JSON.stringify(JSON.parse(JSON.stringify(monthArr).split('"monthId":').join('"key":')))
                      .split('"monthText":')
                      .join('"value":')
                  ),
                ]}
                value={selectedStartMonth}
                handleChange={handleStartMonthValue}
              />
            </Grid>
            <Grid item xs={2}>
              <SelectWrapper
                name="endMonth"
                label="End month"
                options={[
                  { key: "any", value: "Any" },
                  ...JSON.parse(
                    JSON.stringify(JSON.parse(JSON.stringify(monthArr).split('"monthId":').join('"key":')))
                      .split('"monthText":')
                      .join('"value":')
                  ),
                ]}
                value={selectedEndMonth}
                handleChange={handleEndMonthValue}
              />
            </Grid>
            <Grid item xs={2}>
              {/* <Button
                onClick={handleFilter}
                sx={{ mt: 4, pl: 6, pr: 6 }}
                variant="contained"
              >
                Filter
              </Button> */}
              <Button onClick={handleResetFilter} sx={{ mt: 4, ml: 4, pl: 6, pr: 6 }} variant="outlined">
                Reset
              </Button>
            </Grid>
          </Grid>
          <ViewCustomerInvoiceDialog
            viewCustomerInvoiceDialog={viewCustomerInvoiceDialog}
            setViewCustomerInvoiceDialog={setViewCustomerInvoiceDialog}
            countryArr={countryData}
            supplierArr={supplierData}
            currencyArr={currencyData}
            productTypeArr={productData}
            refreshGrid={fetchCustomerInvoices}
          />
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <DataGridExport tableData={getFilteredData()} columns={columns} />
            </div>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "600px",
                }}
              >
                <Typography sx={{ mr: 6, fontSize: 20 }}>Loading</Typography>
                <CircularProgress />
              </div>
            ) : (
              <DataGrid
                // isRowSelectable={(params) => params.row.preFinanceId <= 0 || params.row.preFinanceId == null}
                checkboxSelection
                idProperty="id"
                editMode="cell"
                getRowId={(row) => row.customerInvoiceId}
                onEditCellChange={handleEditCellChange}
                selectionModel={selectedRows}
                onSelectionModelChange={handleSelectionChange}
                components={{ Toolbar: GridToolbar, Footer: BulkEditFooter }}
                density="compact"
                disableColumnSelector
                componentsProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 250 },
                  },
                }}
                rowIndexColumn
                experimentalFeatures={{ newEditingApi: true }}
                rows={getFilteredData()}
                columns={columns}
                pagination
                paginationMode="client"
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[15, 50, 100]}
                sx={{
                  height: 677,
                  width: "100%",
                  borderRadius: 1,
                  backgroundColor: "background.paper",
                  boxShadow: 2,
                  "& .MuiDataGrid-cell:hover": {
                    color: "primary.main",
                  },
                  marginTop: 0,
                }}
              />
            )}
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
};

export default CustomerInvoices;
