import React, { useState, useEffect, useCallback, useContext } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { DataGrid, GridFooter, GridToolbar } from "@mui/x-data-grid";
// import AddClaimForm from "../../components/AddClaimForm";
import {
  Grid,
  Box,
  Card as MuiCard,
  Typography,
  Divider,
  IconButton,
  CircularProgress,
  Stack,
  Tabs,
  Tab,
  Tooltip,
  // Icon,
} from "@mui/material";
// import InfoIcon from "@mui/icons-material/Info";
// import { Delete as DeleteIcon } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { spacing } from "@mui/system";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import axios from "axios";
import ViewClaimDialog from "../../components/ViewClaimDialog";
import DataGridExport from "../../components/DataGridExport";
// import { useMsal } from "@azure/msal-react";
import InfoIcon from "@mui/icons-material/InfoOutlined";
// import { ErrorMessage } from "formik";
import { GlobalContext } from "../../contexts/GlobalContext";

const Card = styled(MuiCard)(spacing);

const CustomerClaims = (props) => {
  // console.table(props);
  const { customerId, customerNumber } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  // const { accounts, instance } = useMsal();
  // const [userId, setUserId] = useState();

  const [activeTab, setActiveTab] = useState(0); // 0 for 'All Claims' tab

  // Add this function to handle tab changes
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const [countryData, setCountryData] = useState([]);
  const [currencyData, setCurrencyData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const countryResponse = await axios.get("/api/v1/countries");
      setCountryData(countryResponse.data);

      const currencyResponse = await axios.get("/api/v1/currencies");
      setCurrencyData(currencyResponse.data);
    }
    fetchData();
  }, []);

  // Modify your existing fetchClaimsByEntityId function or create a new one
  // to fetch and filter claims according to the selected tab
  const fetchAndFilterClaims = useCallback(() => {
    setLoading(true);
    // console.log(customerId);
    if (!customerId) {
      setLoading(false);
      return;
    }

    axios
      .get("/api/v1/claimsByEntityId/" + customerId)
      .then((response) => {
        let filteredClaims = response.data;
        if (activeTab === 0) {
          filteredClaims = filteredClaims.filter((claim) => claim.claimStateId >= 4);
        } else if (activeTab === 1) {
          filteredClaims = filteredClaims.filter((claim) => claim.claimStateId === 4);
        } else if (activeTab === 2) {
          filteredClaims = filteredClaims.filter((claim) => claim.claimStateId === 16 && claim.hasRejectedInvoices === 0);
        } else if (activeTab === 3) {
          filteredClaims = filteredClaims.filter((claim) => claim.claimStateId === 16 && claim.hasRejectedInvoices === 1);
        }
        // return null;
        // Add claim state description
        const claimsWithStateDescription = filteredClaims.map((claim) => {
          let displayText;
          switch (claim.claimStateId) {
            case 1:
              displayText = "Collecting";
              break;
            case 3:
              displayText = "In progress";
              break;
            case 4:
              displayText = "Pending";
              break;
            case 16:
              if (claim.hasRejectedInvoices === 0) {
                displayText = "Finished";
              } else {
                displayText = "(Partly) Rejected";
              }
              break;
            default:
              displayText = ""; // or any other default text you want
          }
          return {
            ...claim,
            claimStateDescription: displayText,
          };
        });

        // Add countryCode
        const claimsWithCountryCode = claimsWithStateDescription.map((claim) => {
          const country = countryData.find((country) => country.countryId === claim.countryId);
          return {
            ...claim,
            countryCode: country ? country.countryCode : null,
          };
        });

        const claimsWithCountryCodeAndFormattedDate = claimsWithCountryCode.map((claim) => {
          const formattedClaimDate = claim.claimDate.split("T")[0];
          const formattedreimbursementDate = claim.reimbursementDate.split("T")[0];

          const country = countryData.find((country) => country.countryId === claim.countryId);

          return {
            ...claim,
            claimDate: formattedClaimDate,
            reimbursementDate: formattedreimbursementDate,
            countryCode: country ? country.countryCode : null,
          };
        });
        const claimsWithCurrencyCode = claimsWithCountryCodeAndFormattedDate?.map((claim) => {
          const selectedCountry = countryData?.find((country) => country.countryId === claim.countryId);
          // console.log(selectedCountry);
          // console.log(currencyData);
          const selectedCurrency = currencyData?.find((currency) => currency.currencyId === selectedCountry?.currency);
          // console.table(selectedCurrency);
          return {
            ...claim,
            currencyCode: selectedCurrency ? selectedCurrency.isoCode : "",
          };
        });

        // Update table data with filtered claims
        setTableData(claimsWithCurrencyCode);
      })
      .catch((error) => {
        console.error("Error fetching claims by entity ID:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [customerId, activeTab, countryData, currencyData]); // include activeTab in the dependencies array

  // Call fetchAndFilterClaims when the component mounts and when activeTab changes
  useEffect(() => {
    fetchAndFilterClaims();
  }, [fetchAndFilterClaims]);

  // useEffect(() => {
  //   const fetchUserId = async () => {
  //     if (accounts.length > 0) {
  //       const activeAccount = accounts[0];
  //       if (activeAccount.idTokenClaims) {
  //         setUserId(activeAccount.idTokenClaims["oid"] || activeAccount.idTokenClaims["sub"]);
  //       }
  //     }
  //   };

  //   fetchUserId();
  // }, [accounts, instance]);

  // useEffect(() => {
  //   // Create an async function inside the useEffect
  //   // console.log(userId);
  //   const fetchUserData = async () => {
  //     try {
  //       if (!userId) {
  //         return;
  //       }

  //       // Await the asynchronous call to complete and get the response
  //       const response = await axios.get("/api/v1/entityByAzureId/" + userId);

  //       // Destructure the required fields from the response data
  //       const { entityId, entityNumber } = response.data;

  //       // Update your state with the response data
  //       setCustomerId(entityId);
  //       setCustomerNumber(entityNumber);
  //     } catch (error) {
  //       // Log or handle the error as needed
  //       console.error("Error fetching entity by Azure ID:", error);
  //     }
  //   };

  //   // Call the async function
  //   fetchUserData();
  // }, [userId]); // Dependency array to re-run the useEffect when userId changes

  const fetchClaimsByEntityId = useCallback(() => {
    setLoading(true);
    axios
      .get("/api/v1/claimsByEntityId/" + customerId)
      .then((response) => {
        // console.log("temp" + response.data);
        // console.log(customerId);
        const tempData = response.data.filter((claim) => claim.claimStateId >= 1);
        setTableData(tempData);
      })
      .catch((error) => {
        console.error("Error fetching claims by entity ID:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [customerId]);

  // useEffect(() => {
  //   fetchClaimsByEntityId();
  // }, [fetchClaimsByEntityId]);

  // console.table(tableData);

  const columns = [
    // {
    //   field: "claimId",
    //   headerName: "Claim ID",
    //   width: 50,
    //   hide: true,
    // },
    { field: "claimCode", headerName: "Claim Code", flex: 0.7 },
    { field: "year", headerName: "Year", flex: 0.3 },
    {
      field: "startMonth",
      headerName: "Start Month",
      flex: 0.4,
      valueFormatter: (params) => params.value.toString().padStart(2, "0"),
    },
    {
      field: "endMonth",
      headerName: "End Month",
      flex: 0.4,
      valueFormatter: (params) => params.value.toString().padStart(2, "0"),
    },
    {
      field: "countryCode",
      headerName: "Country",
      flex: 0.3,
      // valueGetter: (params) =>
      //   countryData.find(
      //     (country) => country.countryId === params.row.countryId
      //   ).countryCode,
    },
    {
      field: "claimStateDescription",
      headerName: "Claim State",
      flex: 0.5,
      // renderCell: (params) => {
      //   let displayText = "";

      //   // Check if the claimStateId is 16 and if the row has any rejected invoices
      //   if (params.value === 16 && params.row.hasRejectedInvoices === 1) {
      //     displayText = "Partly rejected";
      //   } else {
      //     // Based on the claimStateId, set the display text
      //     switch (params.value) {
      //       case 1:
      //         displayText = "Collecting";
      //         break;
      //       case 3:
      //         displayText = "In progress";
      //         break;
      //       case 4:
      //         displayText = "Pending";
      //         break;
      //       case 16:
      //         displayText = "Finished";
      //         break;
      //       default:
      //         displayText = params.value; // or any other default text you want
      //     }
      //   }

      //   return <div>{displayText}</div>;
      // },
    },
    {
      field: "claimDateSortable",
      headerName: "Claim Date",
      flex: 0.4,
      sortingOrder: ["asc", "desc"],
      valueGetter: (params) => {
        const nonRealDates = [
          "0000-00-00",
          "01-01-1900",
          "01-01-1970",
          "00-00-0000",
          "01-01-0001",
          "0001-01-01",
          "0001-01-01T00:00:00",
        ];
        const { claimCode, claimDate } = params.row || {};
        if (!claimCode || !claimDate) return Number.MAX_SAFE_INTEGER;
        const lastTwoDigits = claimCode.slice(-2);
        if (["11", "10"].includes(lastTwoDigits) || nonRealDates.includes(claimDate)) {
          return Number.MAX_SAFE_INTEGER;
        }
        const ts = new Date(claimDate).getTime();
        return isNaN(ts) ? Number.MAX_SAFE_INTEGER : ts;
      },
      sortComparator: (a, b) => a - b,
      renderCell: (params) => {
        const nonRealDates = [
          "0000-00-00",
          "01-01-1900",
          "01-01-1970",
          "00-00-0000",
          "01-01-0001",
          "0001-01-01",
          "0001-01-01T00:00:00",
        ];
        const { claimCode, claimDate } = params.row || {};
        const lastTwoDigits = claimCode ? claimCode.slice(-2) : "";
        if (["11", "10"].includes(lastTwoDigits)) return "N/A";
        if (nonRealDates.includes(claimDate)) return "N/A";
        return formatToLocalDate(claimDate);
      },
    },
    {
      field: "reimbursementDateSortable",
      headerName: "Refund Date",
      flex: 0.6,
      sortingOrder: ["asc", "desc"],
      valueGetter: (params) => {
        const nonRealDates = [
          "0000-00-00",
          "01-01-1900",
          "01-01-1970",
          "00-00-0000",
          "01-01-0001",
          "0001-01-01",
          "0001-01-01T00:00:00",
        ];
        const { claimCode, reimbursementDate, notificationDate } = params.row || {};
        if (!claimCode || !reimbursementDate) return Number.MAX_SAFE_INTEGER;
        const lastTwoDigits = claimCode.slice(-2);
        if (["11", "01", "10"].includes(lastTwoDigits) || nonRealDates.includes(notificationDate)) {
          return Number.MAX_SAFE_INTEGER;
        }
        const ts = new Date(notificationDate).getTime();
        return isNaN(ts) ? Number.MAX_SAFE_INTEGER : ts;
      },
      sortComparator: (a, b) => a - b,
      renderCell: (params) => {
        const nonRealDates = [
          "0000-00-00",
          "01-01-1900",
          "01-01-1970",
          "00-00-0000",
          "01-01-0001",
          "0001-01-01",
          "0001-01-01T00:00:00",
        ];
        const { claimCode, notificationDate } = params.row || {};
        const lastTwoDigits = claimCode ? claimCode.slice(-2) : "";
        if (["01", "10", "11"].includes(lastTwoDigits)) return "N/A";
        if (nonRealDates.includes(notificationDate)) return "N/A";
        return formatToLocalDate(notificationDate);
      },
    },
    {
      field: "payDateSortable",
      headerName: "Payout Date",
      flex: 0.6,
      sortingOrder: ["asc", "desc"],
      valueGetter: (params) => {
        const nonRealDates = [
          "0000-00-00",
          "01-01-1900",
          "01-01-1970",
          "00-00-0000",
          "01-01-0001",
          "0001-01-01",
          "0001-01-01T00:00:00",
        ];
        const { claimCode, payDate } = params.row || {};
        if (!claimCode || !payDate) return Number.MAX_SAFE_INTEGER;
        const lastTwoDigits = claimCode.slice(-2);
        if (lastTwoDigits === "11" || ["00", "10"].includes(lastTwoDigits) || nonRealDates.includes(payDate)) {
          return Number.MAX_SAFE_INTEGER;
        }
        const ts = new Date(payDate).getTime();
        return isNaN(ts) ? Number.MAX_SAFE_INTEGER : ts;
      },
      sortComparator: (a, b) => a - b,
      renderCell: (params) => {
        const nonRealDates = [
          "0000-00-00",
          "01-01-1900",
          "01-01-1970",
          "00-00-0000",
          "01-01-0001",
          "0001-01-01",
          "0001-01-01T00:00:00",
        ];
        const { claimCode, payDate } = params.row || {};
        const lastTwoDigits = claimCode ? claimCode.slice(-2) : "";
        if (["00", "10"].includes(lastTwoDigits)) return "N/A";
        if (lastTwoDigits === "11")
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              Pre-Financed
              <Tooltip
                title="Due to pre-financing, the payout date is related to the individual invoices. Please look at the individual invoices to see when the payment took place."
                enterTouchDelay={0}
                placement="top"
              >
                <InfoIcon sx={{ color: "#376FD0", marginLeft: 1, fontSize: 16, cursor: "pointer" }} />
              </Tooltip>
            </div>
          );
        if (nonRealDates.includes(payDate)) return "N/A";
        return formatToLocalDate(payDate);
      },
    },
    // {
    //   field: "claimGrossAmount",
    //   headerName: "Gross Amount",
    //   flex: 0.5,
    //   align: "right",
    //   renderCell: (params) => (
    //     <div style={{ textAlign: "right", paddingRight: "15px" }}>
    //       {params.value.toLocaleString("nl-NL", {
    //         minimumFractionDigits: 2,
    //         maximumFractionDigits: 2,
    //       })}
    //     </div>
    //   ),
    // },
    {
      field: "currencyCode",
      headerName: "Currency",
      flex: 0.3,
    },
    {
      field: "claimVATAmountLocCur",
      headerName: "VAT Local",
      flex: 0.5,
      align: "right",
      renderCell: (params) => (
        <div style={{ textAlign: "right", paddingRight: "15px" }}>
          {params.value.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      ),
    },
    {
      field: "claimVATAmountEUR",
      headerName: "VAT EUR",
      description: "+/- due to changing exchange rates",
      flex: 0.5,
      align: "right",
      renderCell: (params) => {
        // Find the country data based on countryId from the claim
        const country = countryData.find((country) => country.countryId === params.row.countryId);

        // Calculate the EUR price
        const eurPrice = params.row.claimVATAmountLocCur * (country ? country.exchangeRate : 0);

        // Format the EUR price and conditionally include the InfoIcon to the left if exchangeRate is not 1
        return (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", paddingRight: "15px" }}>
            {country && country.exchangeRate !== 1 && (
              <Tooltip title="VAT EUR: +/- due to changing exchange rates" enterTouchDelay={0} placement="top">
                <InfoIcon
                  sx={{
                    color: "#376FD0",
                    marginRight: 1, // Adjust for spacing between the icon and the number
                    fontSize: 16,
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            )}
            {eurPrice.toLocaleString("nl-NL", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "View",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              onClick={(e) => {
                setViewClaimDialog({
                  isOpen: true,
                  title: "View Claim",
                  params: params.row,
                  countryData: countryData,
                  custInfo: [{ customerId: customerId, customerNumber: customerNumber }],
                  subTitle: "Are you sure you want to delete " + params.row.claimCode + "?",
                  onConfirm: () => {
                    onEditConfirmed(e, params.row);
                  },
                });
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  function formatToLocalDate(dateString) {
    if (!dateString) return "";

    const date = new Date(dateString);
    if (date < new Date("1970-01-01T00:00:00Z")) {
      return "00-00-0000";
    }

    return date.toLocaleDateString();
  }

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [viewClaimDialog, setViewClaimDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    custInfo: [],
  });

  function onEditConfirmed(e, params) {
    // console.log("start return params");
    // console.table(viewClaimDialog.test);
    // console.log("end return params");

    window.location.reload(false);

    setViewClaimDialog({ ...viewClaimDialog, isOpen: false });
  }

  // function onDeleteConfirmed(e, params) {
  //   // return false;
  //   // Delete Claim
  //   axios
  //     .delete("/api/v1/claims/" + params.claimId)
  //     .then((response) => {
  //       // console.log(response);

  //       axios
  //         .put(
  //           "/api/v1/customerInvoices/updateInvoiceRemoveClaimId/" +
  //             params.claimId
  //         )
  //         .then((response) => {
  //           // console.log(response);
  //         });

  //       setNotify({
  //         isOpen: true,
  //         message: "Claim deleted successfully",
  //         type: "success",
  //       });
  //       fetchClaimsByEntityId();
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setNotify({
  //         isOpen: true,
  //         message: "Error deleting claim",
  //         type: "error",
  //       });
  //     })
  //     .finally(() => {
  //       setConfirmDialog({ ...confirmDialog, isOpen: false });
  //     });
  // }

  const [pageSize, setPageSize] = useState(15);

  const [selectedClaimCount, setSelectedClaimCount] = useState(0);
  const [selectedClaimTotalAmount, setSelectedClaimTotalAmount] = useState(0);
  const [selectedClaimTotalVATAmount, setSelectedClaimTotalVATAmount] = useState(0);
  // const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectionChange = (newSelection) => {
    // setSelectedRows(newSelection);
    let selectedRowsAmount = 0;
    let selectedRowsVATAmount = 0;
    // let disablePreFinanced = false;

    newSelection.forEach((rowId) => {
      const selectedRow = tableData.find((row) => row.claimId === rowId);
      if (selectedRow) {
        selectedRowsAmount += selectedRow.claimVATAmountLocCur;

        const country = countryData.find((country) => country.countryId === selectedRow.countryId);

        // Calculate the EUR price
        const eurPrice = selectedRow.claimVATAmountLocCur * (country ? country.exchangeRate : 0);

        selectedRowsVATAmount += eurPrice;
        // console.log(selectedRowsAmount);

        // Check if preFinancedId is greater than 0
        // if (selectedRow.preFinanceId > 0) {
        //   disablePreFinanced = true;
        // }
      }
    });

    setSelectedClaimCount(newSelection.length);
    setSelectedClaimTotalAmount(selectedRowsAmount);
    setSelectedClaimTotalVATAmount(selectedRowsVATAmount);

    // Update the button's disabled state
    // setIsPreFinancedDisabled(disablePreFinanced);
  };

  const BulkEditFooter = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          borderTop: "1px solid #ccc",
          paddingTop: 10,
          paddingLeft: 10,
        }}
      >
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ fontWeight: "bold", marginBottom: 5 }}>Total Selected Claims:</div>
            <div style={{ marginBottom: 5 }}>{selectedClaimCount}</div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", marginLeft: 20 }}>
            <div style={{ fontWeight: "bold", marginBottom: 5 }}>Total VAT Local:</div>
            <div style={{ marginBottom: 5 }}>
              {selectedClaimTotalAmount.toLocaleString("nl-NL", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", marginLeft: 20 }}>
            <div style={{ fontWeight: "bold", marginBottom: 5 }}>Total VAT EUR:</div>
            <div style={{ marginBottom: 5 }}>
              {selectedClaimTotalVATAmount.toLocaleString("nl-NL", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>
          {/* <div
            style={{ display: "flex", flexDirection: "column", marginLeft: 20 }}
          >
            <div style={{ fontWeight: "bold", marginBottom: 5 }}>
              Total VAT Amount EUR:
            </div>
            <div style={{ marginBottom: 5 }}>
              {selectedInvoiceTotalVATAmountEUR.toLocaleString("nl-NL", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div> */}
        </div>
        <div>
          <div>
            <GridFooter
              style={{ borderTop: "none" }}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[15, 50, 100]}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Helmet title="Claims" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          {/* <Typography
            variant="h3"
            gutterBottom
            display="inline"
            style={{ marginBottom: "20px" }} // Adjust the value as needed for space
          >
            List of Claims
          </Typography> */}

          {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Link component={NavLink} to="/">
              Pages
            </Link>
            <Typography>Customers</Typography>
          </Breadcrumbs> */}
        </Grid>
      </Grid>
      <Tabs value={activeTab} onChange={handleTabChange} aria-label="Claim Tabs" sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tab label="All Claims" sx={{ typography: "h6", fontWeight: "bold" }} />
        <Tab label="Pending Claims" sx={{ typography: "h6", fontWeight: "bold" }} />
        <Tab label="Finished Claims" sx={{ typography: "h6", fontWeight: "bold" }} />
        <Tab label="(Partly) Rejected Claims" sx={{ typography: "h6", fontWeight: "bold" }} />
      </Tabs>
      {/* <Divider my={6} /> */}
      <Card mb={6}>
        {/* <AddClaimForm
          customerId={customerId}
          customerNumber={customerNumber}
          refreshTable={fetchClaimsByEntityId}
        /> */}
        <Notification notify={notify} setNotify={setNotify} />
        <Divider />
        <Grid container spacing={2} mt={0}>
          <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
          <ViewClaimDialog
            viewClaimDialog={viewClaimDialog}
            setViewClaimDialog={setViewClaimDialog}
            refreshTable={fetchClaimsByEntityId}
          />
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <DataGridExport tableData={tableData} columns={columns} />
            </div>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "600px",
                }}
              >
                <Typography sx={{ mr: 6, fontSize: 20 }}>Loading</Typography>
                <CircularProgress />
              </div>
            ) : (
              countryData.length > 0 && (
                <DataGrid
                  checkboxSelection
                  idProperty="id"
                  editMode="cell"
                  getRowId={(row) => row.claimId}
                  disableSelectionOnClick
                  disableColumnSelector
                  components={{
                    NoRowsOverlay: () => (
                      <Stack height="100%" alignItems="center" justifyContent="center">
                        No Claims Found
                      </Stack>
                    ),
                    Toolbar: GridToolbar,
                    Footer: BulkEditFooter,
                  }}
                  density="compact"
                  componentsProps={{
                    toolbar: {
                      csvOptions: { disableToolbarButton: true },
                      printOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 250 },
                    },
                  }}
                  onSelectionModelChange={handleSelectionChange}
                  rows={tableData}
                  columns={columns}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[15, 50, 100]}
                  sx={{
                    height: 667,
                    width: "100%",
                    borderRadius: 1,
                    backgroundColor: "background.paper",
                    boxShadow: 2,
                    "& .MuiDataGrid-cell:hover": {
                      color: "primary.main",
                    },
                    marginTop: 0,
                  }}
                />
              )
            )}
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
};

export default CustomerClaims;
