import React, { useState, useCallback, useRef, useContext, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import {
  Box,
  Button,
  IconButton,
  Paper,
  LinearProgress,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import moment from "moment";
import { GlobalContext } from "../../contexts/GlobalContext";
import PlusCircleOutline from "@mui/icons-material/ZoomIn";
import MinusCircleOutline from "@mui/icons-material/ZoomOut";
import DataGridExport from "../../components/DataGridExport"; // Adjust the import path as necessary

const FileUploadComponent = () => {
  const { customerId } = useContext(GlobalContext);
  const [activeTab, setActiveTab] = useState(0); // 0 for 'All Invoices' tab
  const [files, setFiles] = useState([]);
  const [uploadResults, setUploadResults] = useState([]);
  const [open, setOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [allUploaded, setAllUploaded] = useState(false);
  const [buttonText, setButtonText] = useState("Upload");
  // const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });
  const dataGridRef = useRef(null);
  const [uploadHistory, setUploadHistory] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null); // Track only one expanded row
  const [fileCount, setFileCount] = useState(0); // New state for file count

  const rowHeight = 39; // Set a consistent row height for all grids

  console.log("customerId:", customerId); // Debugging the customerId

  useEffect(() => {
    // console.log("Upload History:", uploadHistory); // Debugging the uploadHistory data
  }, [uploadHistory]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    if (newValue === 1) {
      fetchUploadHistory();
    }
  };

  const fetchUploadHistory = useCallback(async () => {
    try {
      const response = await axios.get(`/api/v1/CustomerFileByEntityId/${customerId}`);
      setUploadHistory(response.data);
    } catch (error) {
      console.error("Error fetching upload history:", error);
    }
  }, [customerId]);

  useEffect(() => {
    if (!customerId) {
      return;
    }

    fetchUploadHistory();
  }, [customerId, fetchUploadHistory]);

  const onDrop = useCallback((acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => ({
      file,
      id: Date.now() + Math.random(),
      uploading: false,
      uploaded: false,
      progress: 0,
      size: file.size,
      type: file.type,
      error: file.size > 550000 ? "File is too large, max allowed file size is 350KB" : null,
    }));
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles, ...newFiles];
      const validFileCount = updatedFiles.filter((file) => !file.error).length;
      setFileCount(validFileCount);
      return updatedFiles;
    });
    setButtonText("Upload");
    setAllUploaded(false);
  }, []);

  const handleRemoveFile = (id) => {
    if (!uploading) {
      setFiles((prevFiles) => {
        const updatedFiles = prevFiles.filter((file) => file.id !== id);
        const validFileCount = updatedFiles.filter((file) => !file.error).length;
        setFileCount(validFileCount);
        return updatedFiles;
      });
    }
  };

  const scrollContainerRef = useRef(null);

  const handleUpload = async () => {
    if (buttonText === "Show Results") {
      setOpen(true);
      return;
    }

    setUploading(true);
    const results = [];
    for (const fileObj of files) {
      if (!fileObj.uploaded && !fileObj.error && fileObj.size <= 550000) {
        const fileInfo = {
          entityId: customerId,
          documentType: 1,
          creationDate: moment().format("YYYY-MM-DDThh:mm:ss.SSS") + "Z",
          createdBy: 1,
          fileName: fileObj.file.name,
          fileSize: fileObj.size,
          mimeType: fileObj.type,
        };

        try {
          const fileResponse = await axios.post("/api/v1/CustomerFile", fileInfo);
          const fileId = fileResponse.data.fileId;

          const formData = new FormData();
          formData.append("file", fileObj.file, fileId);

          const response = await axios.post("/api/v1/azureblobstorageCustomerFiles", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              setFiles((prevFiles) => {
                const newFiles = prevFiles.map((f) => (f.id === fileObj.id ? { ...f, progress, uploading: true } : f));

                // Scroll to the file being uploaded
                requestAnimationFrame(() => scrollToFile(fileObj.id, newFiles));

                return newFiles;
              });
            },
          });

          if (response.status === 200) {
            setFiles((prevFiles) =>
              prevFiles.map((f) => (f.id === fileObj.id ? { ...f, progress: 100, uploaded: true, uploading: false, error: null } : f))
            );
            results.push({ id: fileObj.id, file: fileObj.file.name, status: "Uploaded successfully" });
          }
        } catch (error) {
          console.error("Upload failed:", error);
          setFiles((prevFiles) =>
            prevFiles.map((f) => (f.id === fileObj.id ? { ...f, uploading: false, error: error.response?.data || error.message } : f))
          );
          results.push({ id: fileObj.id, file: fileObj.file.name, status: `Error: ${error.response?.data || error.message}` });

          // Scroll one file further in case of error
          requestAnimationFrame(() => scrollToFile(fileObj.id, files, true));
        }
      } else if (fileObj.error) {
        results.push({ id: fileObj.id, file: fileObj.file.name, status: `Error: ${fileObj.error}` });

        // Scroll one file further in case of error
        requestAnimationFrame(() => scrollToFile(fileObj.id, files, true));
      }
    }
    setUploadResults(results);
    setOpen(true);
    setUploading(false);
    setButtonText("Show Results");
    setAllUploaded(true); // Set allUploaded to true after upload is done

    // Ensure the grid is scrolled completely to the bottom when upload is done
    if (scrollContainerRef.current) {
      // console.log(scrollContainerRef.current);
      const gridElement = scrollContainerRef.current;
      gridElement.scrollTop = gridElement.scrollHeight;
      // console.log(gridElement.scrollTop);
    }
  };

  const scrollToFile = (fileId, files, skipError = false) => {
    const rowIndex = files.findIndex((f) => f.id === fileId);
    if (rowIndex !== -1 && scrollContainerRef.current) {
      const gridElement = scrollContainerRef.current;
      const visibleHeight = gridElement.clientHeight;
      let newScrollTop = rowIndex * rowHeight - visibleHeight / 2 + rowHeight / 2;

      if (skipError) {
        newScrollTop += rowHeight;
      }

      requestAnimationFrame(() => {
        gridElement.scrollTop = newScrollTop;
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleToggleRow = (date) => {
    setExpandedRow((prev) => (prev === date ? null : date)); // Toggle row expansion
  };

  const groupedHistory =
    Array.isArray(uploadHistory) && uploadHistory.length > 0
      ? uploadHistory.reduce((acc, file) => {
          const date = moment(file.creationDate).format("YYYY-MM-DD");
          if (!acc[date]) {
            acc[date] = { files: [], totalSize: 0 };
          }
          const fileSize = parseInt(file.fileSize, 10); // Ensure fileSize is an integer
          if (!isNaN(fileSize)) {
            acc[date].files.push({ ...file, id: file.fileId, fileSize: fileSize });
            acc[date].totalSize += fileSize;
          } else {
            console.error(`Invalid fileSize for file: ${file.fileName}`, file.fileSize);
          }
          return acc;
        }, {})
      : {};

  const historyRows = Object.keys(groupedHistory).map((date, index) => ({
    id: date + "-" + index, // Ensure unique id
    date,
    fileCount: groupedHistory[date].files.length,
    totalSize: (groupedHistory[date].totalSize / 1024).toFixed(2) + " KB", // Perform division and conversion
  }));

  const detailedHistoryRows = (date) => {
    return groupedHistory[date].files.map((file, index) => {
      const fileSize = Number(file.fileSize); // Ensure fileSize is a number
      return {
        id: `${file.fileId}-${index}`, // Ensure unique id
        fileName: file.fileName,
        mimeType: file.mimeType,
        fileSize: (fileSize / 1024).toFixed(2) + " KB", // Convert size to KB
        creationDate: moment(file.creationDate).format("YYYY-MM-DD HH:mm:ss"),
      };
    });
  };

  const iconStyle = { height: "24px", width: "24px" }; // Adjusted to fit within 30px row height
  const iconButtonStyle = {
    height: "30px",
    width: "30px",
    padding: "0",
    margin: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const rowStyle = { height: "30px", borderBottom: "1px solid #ccc", padding: "4px 0 4px 12px", boxSizing: "border-box" };
  const detailedRowStyle = { height: "30px", borderBottom: "1px solid #ccc", padding: "8px 0 8px 12px", boxSizing: "border-box" };
  const thStyle = {
    height: "30px",
    borderBottom: "1px solid #ccc",
    padding: "4px 0 4px 8px",
    textAlign: "left",
    boxSizing: "border-box",
  };

  // Apply rowStyle to td and th elements in renderTable
  const renderTable = (columns, rows, expandDate = null) => (
    <table style={{ width: "100%", borderCollapse: "collapse" }}>
      <thead>
        <tr style={rowStyle}>
          {columns.map((column) => (
            <th key={column.field} style={thStyle}>
              {column.headerName}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <React.Fragment key={row.id}>
            <tr style={rowStyle}>
              {columns.map((column) => (
                <td key={column.field} style={rowStyle}>
                  {column.renderCell ? column.renderCell({ row }) : row[column.field]}
                </td>
              ))}
            </tr>
            {expandDate === row.date && (
              <tr>
                <td colSpan={columns.length} style={{ padding: 0, margin: 0 }}>
                  <div style={{ padding: "0 0 0 40px", margin: "0" }}>
                    <table style={{ padding: "0 0 0 25px", width: "100%", borderCollapse: "collapse" }}>
                      <thead>
                        <tr style={rowStyle}>
                          {[
                            { field: "fileName", headerName: "File Name", flex: 1.5 },
                            { field: "mimeType", headerName: "MIME Type", flex: 1 },
                            { field: "fileSize", headerName: "File Size", flex: 1 },
                            { field: "creationDate", headerName: "Creation Date", flex: 1.5 },
                          ].map((column) => (
                            <th key={column.field} style={thStyle}>
                              {column.headerName}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {detailedHistoryRows(row.date).map((detailRow) => (
                          <tr key={detailRow.id} style={detailedRowStyle}>
                            {[{ field: "fileName" }, { field: "mimeType" }, { field: "fileSize" }, { field: "creationDate" }].map(
                              (column) => (
                                <td key={column.field} style={detailedRowStyle}>
                                  {detailRow[column.field]}
                                </td>
                              )
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );

  const columns = [
    { field: "fileName", headerName: "File Name", flex: 1.5 },
    { field: "size", headerName: "File Size", flex: 1 },
    { field: "type", headerName: "MIME Type", flex: 1 },
    {
      field: "progress",
      headerName: "Upload Progress",
      flex: 2,
      renderCell: (params) => (
        <Box sx={{ width: "100%" }}>
          <LinearProgress variant="determinate" value={params.row.progress} />
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "100%", height: "30px" }}>
          {params.row.uploaded ? (
            <CheckCircleIcon color="success" style={iconStyle} />
          ) : params.row.error ? (
            <>
              <Tooltip title={params.row.error}>
                <ErrorIcon color="error" style={iconStyle} />
              </Tooltip>
              {!uploading && !params.row.uploaded && !allUploaded && (
                <IconButton onClick={() => handleRemoveFile(params.row.id)} style={iconButtonStyle}>
                  <DeleteIcon style={iconStyle} />
                </IconButton>
              )}
            </>
          ) : (
            !params.row.uploading &&
            !params.row.uploaded &&
            !allUploaded && (
              <IconButton onClick={() => handleRemoveFile(params.row.id)} disabled={uploading} style={iconButtonStyle}>
                <DeleteIcon style={iconStyle} />
              </IconButton>
            )
          )}
        </Box>
      ),
    },
  ];

  const rows = files.map((fileObj) => ({
    id: fileObj.id,
    fileName: fileObj.file.name,
    size: (fileObj.size / 1024).toFixed(2) + " KB",
    type: fileObj.type,
    progress: fileObj.progress,
    uploaded: fileObj.uploaded,
    error: fileObj.error,
    uploading: fileObj.uploading,
  }));

  const resultColumns = [
    { field: "file", headerName: "File Name", flex: 1.5 },
    { field: "status", headerName: "Status", flex: 4 },
  ];

  const historyColumns = [
    { field: "date", headerName: "Upload Date", flex: 1 },
    { field: "fileCount", headerName: "File Count", flex: 1 },
    { field: "totalSize", headerName: "Total Size", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <IconButton onClick={() => handleToggleRow(params.row.date)} style={iconButtonStyle}>
          {expandedRow === params.row.date ? <MinusCircleOutline /> : <PlusCircleOutline />}
        </IconButton>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Tabs value={activeTab} onChange={handleTabChange} aria-label="Invoice Tabs">
        <Tab label="Upload Invoices" sx={{ typography: "h6", fontWeight: "bold" }} />
        <Tab label="Upload History" sx={{ typography: "h6", fontWeight: "bold" }} />
      </Tabs>

      <Box sx={{ p: 2 }}>
        {activeTab === 0 ? (
          <React.Fragment>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Box
                {...getRootProps()}
                sx={{
                  border: "2px dashed gray",
                  borderRadius: "4px",
                  p: 4,
                  textAlign: "center",
                  mb: 2,
                  cursor: "pointer",
                  flex: 1,
                }}
              >
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
              </Box>
            </Box>
            <Paper sx={{ height: 635, mb: 2, overflow: "hidden", position: "relative" }} ref={dataGridRef}>
              <Box ref={scrollContainerRef} sx={{ height: "calc(100% - 50px)", overflow: "auto" }}>
                {renderTable(columns, rows)}
              </Box>
              <Box
                sx={{
                  height: 50,
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: 2,
                  borderTop: "1px solid #ccc",
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                  backgroundColor: "#fff",
                }}
              >
                <p>Total invoices to be uploaded: {fileCount}</p>
              </Box>
            </Paper>

            <Button variant="contained" color="primary" onClick={handleUpload} disabled={uploading}>
              {buttonText}
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Paper sx={{ height: 635, mb: 2, overflow: "auto" }}>{renderTable(historyColumns, historyRows, expandedRow)}</Paper>
          </React.Fragment>
        )}
      </Box>

      {/* Dialog for Upload Results */}
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle>Upload Results</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <DataGridExport tableData={uploadResults} columns={resultColumns} />
          </Box>
          <Box sx={{ height: 635, width: "100%" }}>{renderTable(resultColumns, uploadResults)}</Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default FileUploadComponent;
